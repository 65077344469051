import { formatDate } from '@theme/utils';

import { formatDateWithDay } from '@theme/utils';
import { Icon } from './icon';
import { useLocalization } from '@akinon/next/hooks';
import { twMerge } from 'tailwind-merge';

export const ShippingDeliveryDate = ({
  onSiparis,
  widgetDeliveryDate,
  className = '',
  dateWrapperClassName = '',
  iconSize = 20
}) => {
  const { t } = useLocalization();

  if (onSiparis) {
    onSiparis = formatDateWithDay(onSiparis);
  }


  return (
    <div
      className={twMerge(
        'flex items-center justify-start w-full gap-x-2',
        className
      )}
    >
      <Icon name="truck" size={iconSize} className="text-plum-cheese" />
      <span className={twMerge("font-sans text-base font-medium text-black", dateWrapperClassName)}>
        {onSiparis ? (
          onSiparis + ' ' + t('product.on_cargo')
        ) : (
          <>
            {widgetDeliveryDate ? formatDate(widgetDeliveryDate) : ''}
            {widgetDeliveryDate ? ' ' + t('product.on_cargo') : ''}
          </>
        )}
      </span>
    </div>
  );
};
